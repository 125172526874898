:root {
  --menu-color: #F2F2F2;
}

.header {
  background-color: var(--menu-color);
}

.main {
  height: 100vh;
  background: url(../img/landing/landing1.jpg) no-repeat center;
  background-size: cover;
  animation: slide 20s infinite;
  position: relative;
}

.main__gradient {
  position: absolute;
  top: 0;
  height: 100vh;
  width: 100%;
  background: linear-gradient(90deg, rgba(51, 28, 0, 0.86) 0%, rgba(0, 0, 0, 0) 100%);
}

.main__content {
  position: absolute;
  bottom: 0;
  padding-left: 3.3rem;
  padding-bottom: 5.1rem;
  font-family: "Alegreya", serif;
}

.main__content__title {
  color: #FFA149;
  font-size: 8rem;
  font-weight: 400;
  margin-bottom: 1.3rem;
}

.main__content__subtitle {
  color: #FFFFFF;
  font-size: 3rem;
  font-weight: 400;
  margin-top: 1.3rem;
  margin-bottom: 3.4rem;
}

.main__content__action-button a {
  color: #FFA149;
  font-family: "Alegreya Sans", sans-serif;
  font-weight: 500;
  font-size: 2.25rem;
}

.main__content__action-button a:hover {
  font-weight: bold;
}

@keyframes slide {
  0%{
    background-image: url(../img/landing/landing1.jpg);
    background-position: center;
    background-size: cover;
  }
  33%{
    background-image: url(../img/landing/landing2.jpg);
    background-position: center;
    background-size: cover;
  }
  67%{
    background-image: url(../img/landing/landing3.jpg);
    background-position: center;
    background-size: cover;
  }
}

@media (max-width: 768px) {
  .main__content__title {
    font-size: 5rem;
  }

  .main__content__subtitle {
    font-size: 2.2rem;
  }

  .main__content__action-button a {
    font-size: 2rem;
  }
}
